<template>
  <LayoutDataTable
    :isLoading="showTable"
    :searchQuery="searchQuery"
    :perPage="perPage"
    :meta="dataMeta"
    :currentPage="currentPage"
    :totals="totals"
    :searchPlaceholder="$t('lbl_search_name__brand_or_sku')"
    :isShowStatusDropdown="true"
    :dropdownOptions="dropdownOptions"
    :colsRight="5"
    @setPerPage="(value) => (perPage = value)"
    @setStatus="(value) => (currentStatus = value, currentPage = 1)"
    @setCurrentPage="(value) => (currentPage = value)"
    @setSearchQuery="(value) => (searchQuery = value, currentPage = 1)"
  >
    <template #cta>
      <b-button
        :to="{
          name:
            productType === PRODUCT_TYPES.PRODUCT
              ? 'add-product'
              : 'add-service',
        }"
        variant="primary"
        class="ml-1"
      >
        <span class="text-nowrap">{{ $t("lbl_add_product") }}</span>
      </b-button>
    </template>
    <template #default>
      <b-table
        ref="refProductsTable"
        class="position-relative"
        :items="fetchProductsList"
        :filter="{
          type: productType,
        }"
        responsive
        :fields="tableColumnsV2"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        hover
        :empty-text="$t('lbl_no_matching_records_found')"
        :sort-desc.sync="isSortDirDesc"
        @row-clicked="handleViewRecord"
      >
        <template #cell(sku)="scope">
          <span class="d-inline-block">
            {{
            scope.item.sku || appUtils.randomCode(10)
            }}
          </span>
        </template>
        <template #cell(primary_image)="scope">
          <img
            class="border rounded-lg"
            :src="scope.item.primary_image"
            :alt="scope.item.name"
            :height="48"
            @error="(e) => appUtils.onHandleErrorLoadImage(e)"
          />
        </template>
        <template #cell(name)="scope">
          <span class="d-inline-block text-truncate" style="max-width: 150px">
            {{
            scope.item.name
            }}
          </span>
        </template>
        <template #cell(category)="scope">
          <!-- <b-badge
            :style="index > 0 ? {
              marginLeft: `8px`
            } : {}"
            v-for="(cate, index) in scope.item.category"
            :key="cate.id"
          >{{ cate.name }}</b-badge>-->
          <b-badge>{{ scope.item.category.name }}</b-badge>
        </template>
        <template #cell(type)="scope">
          <b-badge :variant="getProductType(scope.item.type).variant">
            {{
            $t(Object.keys(PRODUCT_TYPES)[scope.item.type - 1])
            }}
          </b-badge>
        </template>
        <template #cell(default_price)="scope">
          <span class="text-nowrap">
            {{
            scope.item.default_price === 0
            ? $t("lbl_contact")
            : appUtils.numberFormat(scope.item.default_price)
            }}
          </span>
        </template>
        <template #cell(status)="scope">
          <div
            class="d-flex align-items-center justify-content-center text-nowrap"
            :set="(status = getProductStatus(scope.item.status))"
          >
            <span
              v-b-tooltip.hover
              :title="
                scope.item.status === PRODUCT_ON_HODO.ARCHIVE_AVAILABLE
                  ? $t('ARCHIVE_AVAILABLE')
                  : $t(Object.keys(PRODUCT_ON_HODO)[scope.item.status - 1])
              "
              :class="status.classes"
              class="d-flex text-sm border rounded-circle"
              :style="{
                width: `24px`,
                height: `24px`,
              }"
            >
              <feather-icon class="m-auto" :icon="status.icon" />
            </span>
          </div>
        </template>
        <template #cell(created_at)="scope">
          <span class="text-nowrap">
            {{
            formatDate(scope.item.created_at)
            }}
          </span>
        </template>
        <template #cell(available)="data">
          <div class="text-right text-nowrap" @click.stop>
            <div>
              <b-form-checkbox
                :checked="data.item.is_available === 1"
                class="custom-control-success"
                name="check-button"
                switch
                @change="handleChangeStatus(data.item)"
              />
            </div>
          </div>
        </template>
        <template #cell(action)="scope">
          <div class="text-right text-nowrap" @click.stop>
            <b-button
              v-if="
                scope.item.status === PRODUCT_ON_HODO.AVAILABLE &&
                !scope.item.is_added_inventory &&
                productType === PRODUCT_TYPES.SERVICE
              "
              v-b-tooltip.hover.top
              v-b-modal.hodo-modal-scrollable
              variant="outline-primary"
              size="sm"
              :title="$t('lbl_publish_to_e_commerce')"
              @click="handleAddProdInInventory(scope.item)"
            >
              <feather-icon size="16" icon="ArrowUpCircleIcon" />
            </b-button>
            <b-button
              v-if="
                scope.item.status === PRODUCT_ON_HODO.UNAVAILABLE &&
                !scope.item.is_added_inventory
              "
              v-b-tooltip.hover.top
              v-b-modal.hodo-modal-scrollable
              variant="outline-primary"
              size="sm"
              :title="$t('lbl_request_to_verify')"
              @click="handleRequestToVerify(scope.item)"
            >
              <feather-icon size="16" icon="CheckCircleIcon" />
            </b-button>
            <!-- <b-button
              v-if="scope.item.status !== PRODUCT_ON_HODO.AVAILABLE"
              v-b-tooltip.hover.top
              v-b-modal.hodo-modal-scrollable
              variant="outline-primary ml-1"
              size="sm"
              :title="$t('lbl_view_detail')"
              :to="scope.item.status === PRODUCT_ON_HODO.AVAILABLE?'':{ path: `/products/${scope.item.slug}-${scope.item.id}` }"
            >
              <feather-icon size="16" icon="EyeIcon" />
            </b-button>-->
            <b-button
              v-if="
                scope.item.status !== PRODUCT_ON_HODO.AVAILABLE &&
                scope.item.status !== PRODUCT_ON_HODO.ARCHIVE_AVAILABLE
              "
              v-b-tooltip.hover.top
              variant="outline-danger ml-1"
              size="sm"
              :title="$t('lbl_delete_record', { 0: scope.item.id })"
              @click="deleteRecord(scope.item, 'delete')"
              record
            >
              <feather-icon size="16" icon="Trash2Icon" />
            </b-button>
            <b-button
              v-else-if="
                scope.item.status !== PRODUCT_ON_HODO.ARCHIVE_AVAILABLE
              "
              v-b-tooltip.hover.top
              variant="outline-danger ml-1"
              size="sm"
              :title="$t('lbl_archive_record', { 0: scope.item.id })"
              @click="deleteRecord(scope.item, 'archive')"
              record
            >
              <feather-icon size="16" icon="ArchiveIcon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </template>
  </LayoutDataTable>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import appUtils from '@/utils/appUtils'
import {
  PRODUCT_ON_HODO,
  PRODUCT_ON_INVENTORY_STATUS,
  PRODUCT_TYPES
} from '@/utils/constant'
import { ref } from '@vue/composition-api'
import { BBadge, BButton, BModal, BTable, BTooltip } from 'bootstrap-vue'
import LayoutDataTable from '../../layouts/table/LayoutDataTable.vue'
import useProductsList from './useProductsList'

export default {
  name: 'ProductsList',
  components: {
    LayoutDataTable,
    BTable,
    BButton,
    BTooltip,
    BModal,
    BBadge
  },
  data() {
    return {
      appUtils,
      PRODUCT_TYPES,
      PRODUCT_ON_HODO
    }
  },
  computed: {
    productType() {
      return this.$route?.meta?.type
    },
    layoutType() {
      return this.$route.name
    },
    dropdownOptions() {
      return [
        {
          value: null,
          statusName:
            this.productType === PRODUCT_TYPES.PRODUCT
              ? this.$t('lbl_on_sale.product')
              : this.$t('lbl_on_sale.service')
        },
        {
          value: 50,
          statusName:
            this.productType === PRODUCT_TYPES.PRODUCT
              ? this.$t('lbl_off_sale.product')
              : this.$t('lbl_off_sale.service')
        }
      ]
    },
    tableColumnsV2() {
      return [
        // { key: "id", label: "#ID", sortable: false, thClass: "text-nowrap" },

        { key: 'sku', sortable: false, thClass: 'text-nowrap' },
        {
          key: 'primary_image',
          label: this.$t('lbl_image'),
          sortable: false,
          thClass: 'text-nowrap'
        },
        {
          key: 'name',
          label: this.$t('lbl_name'),
          sortable: false,
          thClass: 'text-nowrap'
        },
        {
          key: 'category',
          label: this.$t('lbl_category'),
          sortable: false,
          thClass: 'text-nowrap'
        },
        {
          key: 'type',
          label: this.$t('lbl_type'),
          sortable: false,
          thClass: 'text-nowrap'
        },
        {
          key: 'default_price',
          label: `${this.$t('lbl_unit_price')} (VNĐ)`,
          sortable: false,
          thClass: 'text-nowrap'
        },
        {
          key: 'status',
          label: this.$t('lbl_hodo_verified'),
          sortable: false,
          thClass: 'text-nowrap text-center'
        },
        this.productType === PRODUCT_TYPES.PRODUCT
          ? {
              key: 'available',
              label: this.$t('lbl_available'),
              thClass: 'text-center text-nowrap'
            }
          : {},
        { key: 'action', label: '', thClass: 'text-right text-nowrap' }
      ]
    }
  },

  methods: {
    refreshTable() {
      this.showTable = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.refetchData()
        this.showTable = false
      }, 500)
    },
    formatDate(time) {
      return moment(time).format('HH:mm:ss DD/MM/YYYY')
    },
    deleteRecord(record, action) {
      if (!record?.id) {
        return
      }

      const nodeEl = this.$createElement
      const messageVNode = nodeEl('div', {
        domProps: {
          innerHTML:
            action === 'delete'
              ? this.$t('lbl_are_you_sure_want_to_delete_record', {
                  0: record?.id
                })
              : this.$t('lbl_are_you_sure_want_to_archive_record', {
                  0: record?.id
                })
        }
      })

      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: this.$t('lbl_please_confirm'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('lbl_yes'),
          cancelTitle: this.$t('lbl_cancel'),
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$rf.getRequest('CommonRequest').deleteProduct(record?.id)
            this.refreshTable()
          }
        })
        .catch(err => {
          // An error occurred
          console.log(err)
        })
    },
    getImage(imageUrl) {
      const img = appUtils.checkingImageUrl(imageUrl)
      console.log(img)
      return img
    },
    handleViewRecord(record) {
      const routeType =
        record.type === PRODUCT_TYPES.PRODUCT ? 'products' : 'services'
      this.$router.push({
        path: `/${routeType}/${record.slug}-${record.id}`
      })
    },
    handleAddProdInInventory(data = {}) {
      if (!data.id || !data.supplier_id) return

      this.$bvModal
        .msgBoxConfirm(
          this.$t(
            'lbl_please_confirm_that_you_want_add_product_into_inventory_'
          ),
          {
            title: '',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('lbl_yes'),
            cancelTitle: this.$t('lbl_cancel'),
            hideHeaderClose: false,
            centered: true
          }
        )

        .then(async success => {
          if (success) {
            const response = await this.addProdInInventory(data)
            if (response) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('lbl_add_product_into_inventory_success'),
                  icon: 'CheckCircleIcon',
                  variant: 'success'
                }
              })
              this.refreshTable()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t(
                    'lbl_something_went_wrong_when_add_product_into_inventory'
                  ),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
            }
          }
        })
    },
    handleRequestToVerify(data = {}) {
      if (!data?.id || !data?.supplier_id) return

      this.$bvModal
        .msgBoxConfirm(
          this.$t(
            'lbl_please_confirm_that_you_want_request_verify_product_to_hodo_'
          ),
          {
            title: '',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('lbl_yes'),
            cancelTitle: this.$t('lbl_cancel'),
            hideHeaderClose: false,
            centered: true
          }
        )
        .then(async success => {
          if (success) {
            const status = PRODUCT_ON_HODO.PENDING_AVAILABLE
            const response = await this.updateStatusProductById(data, status)

            if (response) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('lbl_verification_request_sent_successfully'),
                  icon: 'CheckCircleIcon',
                  variant: 'success'
                }
              })
              this.refreshTable()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t(
                    'lbl_something_went_wrong_when_sending_verification_request'
                  ),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
            }
          }
        })
    },
    async handleChangeStatus(product) {
      const params = {
        is_available:
          product?.is_available === PRODUCT_ON_INVENTORY_STATUS.AVAILABLE
            ? PRODUCT_ON_INVENTORY_STATUS.UNAVAILABLE
            : PRODUCT_ON_INVENTORY_STATUS.AVAILABLE
      }

      return this.$store
        .dispatch('inventory/updateProductStatus', {
          id: product.id,
          params
        })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: this.$t('lbl_update_status_success'),
              icon: 'CheckCircleIcon',
              variant: 'success'
            }
          })
          return true
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: this.$t('lbl_update_status_fail'),
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            }
          })
          return false
        })
        .finally(() => {
          this.refreshTable()
        })
    }
  },
  setup() {
    const dataTag = ref({})
    const mixData = useProductsList()
    return { ...mixData, dataTag }
  }
}
</script>

<style lang="scss">
.b-table-details {
  background-color: #f6f6f9;
}
</style>
